import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex gap-x-4 flex-row flex-nowrap mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_delivery_performance_summary_card = _resolveComponent("delivery-performance-summary-card")!
  const _component_delivery_performance_detail_card = _resolveComponent("delivery-performance-detail-card")!
  const _component_delivery_performance_per_route_card = _resolveComponent("delivery-performance-per-route-card")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_Title, { label: "Performa Pengiriman" }),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_delivery_performance_summary_card, { class: "w-4/12" }),
      _createVNode(_component_delivery_performance_detail_card, { class: "w-5/12" }),
      _createVNode(_component_delivery_performance_per_route_card, { class: "w-3/12" })
    ])
  ]))
}